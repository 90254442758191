import $ from "jquery";

/**
 * Copies given text to clipboard.
 * @param {string} text The text to copy.
 * @returns {boolean|void}
 */
export function copyToClipboard(text) {
  if (window.clipboardData && window.clipboardData.setData) {
    return window.clipboardData.setData("Text", text);
  } else if (
    document.queryCommandSupported &&
    document.queryCommandSupported("copy")
  ) {
    let textarea = document.createElement("textarea");
    textarea.textContent = text;
    textarea.style.position = "fixed";
    document.body.appendChild(textarea);
    textarea.select();
    try {
      return document.execCommand("copy");
    } catch (ex) {
      return false;
    } finally {
      document.body.removeChild(textarea);
    }
  }
}

/**
 * Decorates a button to copy a field's text.
 * @param {string} selector                The selector of the button(s) to decorate.
 * @param {string} [options]               Decorate options.
 * @param {string} [options.groupSelector] The selector of the input group where both the button and input reside.
 * @param {string} [options.inputSelector] The selector of the input to decorate, relative to the button.
 * @param {object} [options.copiedTooltip] Tooltip to show on copy.
 */
export function decorateInputCopy(selector, options) {
  const opts = Object.assign(
    {
      groupSelector: ".input-group",
      inputSelector: ".form-control"
    },
    options
  );
  document.querySelectorAll(selector).forEach(btn => {
    btn.onclick = function () {
      const field = this.closest(opts.groupSelector).querySelector(
        opts.inputSelector
      );
      copyToClipboard(field.value);
      if (opts.copiedTooltip) {
        const originalTooltip = this.getAttribute("data-original-title");
        $(this).attr("data-original-title", opts.copiedTooltip).tooltip("show");
        this.setAttribute("data-original-title", originalTooltip);
      }
    };
  });
}

/***
 * Decorates a form to handle validation.
 * @param selector The form query selector
 */
export function decorateFormValidation(selector) {
  document.querySelectorAll(selector).forEach(form => {
    form.addEventListener(
      "submit",
      function (event) {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        form.classList.add("was-validated");
      },
      false
    );
  });
}
